.App {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

section {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.section-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.section-title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 40px;
}

.section-text {
  margin-bottom: 40px; /* Zwiększony odstęp */
  color: #fff;
}

.section-form {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  color: #fff;
}

.section1 .scroll-down-arrow {
  animation: bounce 2s infinite;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #faad14;
  border-radius: 50%;
  padding: 15px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2 .scroll-down-arrow {
  animation: bounce 2s infinite;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1d3972;
  border-radius: 50%;
  padding: 15px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #faad14;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 600px) {
  .App {
    scroll-snap-type: none;
    overflow-y: auto;
  }

  section {
    height: auto;
    padding: 20px 0;
  }

  .section-content {
    flex-direction: column;
    padding: 20px;
  }
  .icon-container {
    flex-direction: column;
    align-items: center;
  }
  .icon-container > div {
    margin-bottom: 20px;
  }

  .section25-container {
    flex-direction: column;
  }

  .section25-part1 {
    background-color: #1d3972;
  }

  .section25-grid-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .section25-tile-mobile {
    background-color: #faad14 !important;
    min-width: 80% !important; /* Minimalna szerokość 80% szerokości strony */
    height: 250px !important;
    margin: 10px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .section25-tile-mobile .section25-number {
    color: #1d3972;
    font-size: 2rem !important;
    font-weight: bold !important;
  }

  .section25-tile-mobile .section25-text {
    color: #1d3972;
    text-align: center;
    margin-top: 10px;
  }

  /* Specyficzne klasy */
  .section25-grid-mobile .MuiGrid-item {
    min-width: 80% !important;
  }
}

@media (min-width: 1200px) {
  .css-1oqqzyl-MuiContainer-root {
    max-width: 1200px;
    top: -60px;
  }
}

.responsive-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* Stylizacja dla tła */
.section1 .background-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.section1 .background-left {
  width: 70%;
  background-color: #1d3972;
}

.section1 .background-right {
  width: 30%;
  background-color: #faad14;
}

.section1 .background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section2 .background-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.section2 .background-left {
  width: 30%;
  overflow: hidden;
}

.section2 .background-right {
  width: 70%;
  background-color: #faad14;
}

.section2 .background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Stylizacja dla kafelków w sekcji 2 */
.section2-tile {
  background-color: #faad14;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 200px;
  width: 250px;
  margin: 0 auto 20px auto;
}

.section2-icon {
  color: #1d3972;
  margin-bottom: 5px;
}

.section2-text {
  color: #1d3972;
  font-weight: bold;
  text-align: center;
}

.section2-description {
  color: #1d3972;
  font-weight: bold;
  text-align: justify;
  line-height: 1.6;
  margin-left: 20px;
}

.section2-image {
  width: 30%;
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.section2-image:hover {
  transform: scale(1.05);
}

.image-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal-close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #1d3972;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Sekcja 3 */
.section3 {
  position: relative;
  background-color: #1d3972; /* Kolor niebieski */
  color: #fff;
  padding: 100px 0;
}

.section3 .section-content {
  height: 100%;
}

.section3 .section3-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  z-index: 2;
  position: relative;
  width: 60%;
  margin: auto;
}

.section3 .section3-form {
  /* Dodatkowe style dla formularza */
}

.section3 .background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.section3 .background-left {
  width: 40%;
  background-image: url('https://bialaperla.pl/wp-content/uploads/2020/09/bp2wiz.jpg');
  background-size: cover;
  background-position: center;
}

.section3 .background-right {
  width: 60%;
  background-color: #1d3972; /* Kolor niebieski */
}

/* Tła dla sekcji mobilnych */
.section1-part1-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.section1-part1-background .background-left {
  width: 70%;
  background-color: #1d3972;
}

.section1-part1-background .background-right {
  width: 30%;
  background-color: #faad14;
}

.section2-part1-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d3972;
  z-index: 1;
}

.section2-part2-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #faad14;
  z-index: 1;
}

/* Sekcja 25 */
.section25 {
  display: flex;
  flex-direction: column;
}

.section25-container {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.section25-top {
  height: 30vh;
  width: 100%;
  background-image: url('https://bialaperla.pl/wp-content/themes/biala-perla/img/contact_top_back.jpg');
  background-size: cover;
  background-attachment: scroll;
}

.section25-bottom {
  height: 70vh;
  width: 100%;
  background-color: #1d3972;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section25-tile {
  background-color: #faad14 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 230px; /* Zwiększona wysokość o 15% */
  width: 300px; /* Zwiększona szerokość o 20% */
  position: relative;
  top: -100px; /* Nachodzenie na górną część */
}

.section25-number {
  color: #1d3972;
  font-size: 2rem !important;
  font-weight: bold !important;
}

.section25-text {
  color: #1d3972;
  text-align: center;
  margin-top: 10px;
}

.section25 .MuiGrid-item {
  display: flex;
  justify-content: center;
}
